import React from 'react'
import { IoIosSearch } from 'react-icons/io';

import Container from 'components/Container'
import Box from 'components/Box'
import Flex from 'components/Flex'
import Text from 'components/Text'
import Image from 'components/Image'
import Link from 'components/Link'
import Button from 'components/Button'
import NewsLayout from 'components/NewsLayout'
import FullpageVerticalCenter from 'components/FullpageVerticalCenter'
import theme, { responsive } from 'components/ThemeProvider/theme';

import useResponsive from 'contexts/mediaQuery/useResponsive'

import pic from './pic.svg';
import text from './text.svg';
import processImg from './processimg.png';
import mobileprocess from './mobileprocess.png';
import Pen from './Pen';

const IndexPage = () => {
  const { isMobile } = useResponsive()
  const adjust = theme.headerHeight.map(em => '-' + em)
  return (
    <Box bg="white" mt={adjust} overflowX="hidden">
      <Container height="100vh">
        <FullpageVerticalCenter>
          <Flex flexDirection={responsive('column', null, 'row')} alignItems="center" mr={responsive(0, null, '-3.5em')}>
            <Box width={responsive(1, 4 / 5, 1/2)} px={responsive('1.25em', '1em')} textAlign="center">
              <Box.Absolute width="0" height="0" overflow="hidden" opacity="0">
                <Text.H1>線上聲請調解</Text.H1>
              </Box.Absolute>
              <Image src={text} alt="線上聲請調解" />
              <Box position="relative" display="inline-block" mt={responsive('1.5em', null, '0.5em')}>
                <Box position="absolute" width="100%" borderRadius="0.5em" py="0.25em" bg="lightPurple" bottom="0" transform="translateY(20%)" />
                <Box position="relative" textAlign="center">
                  <Text.H2 fontSize={responsive('1.325em', '2.25em')} lineHeight="1">簡政便民、行動治理、智能城市</Text.H2>
                </Box>
              </Box>
            </Box>
            <Box width={responsive(1, 1/2)} mx="1em" mt={responsive('2em', null, 0)} mr={responsive('-1em', 0)}>
              <Image src={pic} alt="主視覺：幾個人站在電腦前方的畫面，電腦上有汽車、房屋、圖表" />
            </Box>
          </Flex>
          <Box mt="2.375em" textAlign="center">
            <Button
              mx="0.5rem"
              as={Link}
              to="/apply"
              mt="0.625em"
              width={responsive('17rem', '22rem')}
              fontSize={responsive('1.5em','1.75em')}
              leftIcon={Pen}
            >線上聲請調解</Button>
            <Button
              mx="0.5rem"
              as={Link}
              to="/progress"
              mt="0.625em"
              width={responsive('17rem', '22rem')}
              fontSize={responsive('1.5em', '1.75em')}
              leftIcon={IoIosSearch}
            >調解案件進度查詢</Button>
          </Box>
        </FullpageVerticalCenter>
      </Container>
      <Box
        bg="lightPurple"
        position="relative"
        pt={responsive('6em', '10.75em')}
        pb={responsive('3em', '5em')}
      >
        <Box position="absolute" py={responsive('2em', '4em')} left="-3%" right="-3%" top="0" borderRadius="0 0 50% 50%" bg="white" transform="translateY(-40%)" />
        <Container>
          <Text.H3 ml={responsive('1.25em', 0)} whiteSpace={responsive('pre-wrap', 'nowrap')} fontSize={responsive('1.375em', '1.875em')} color="lightBlack">
            {'新北市政府辦理調解事件 -\n標準作業流程圖(含公所)'}
          </Text.H3>
          <Text>*以案件實際狀況處理</Text>
          <Box bg="white" borderRadius="1em" mt="2em" pb="2.75em">
            {isMobile ? <Image src={mobileprocess} alt="調解作業流程圖" /> : <Image src={processImg} alt="調解作業流程圖" />}
          </Box>
          <Box mt={responsive('4.375em', '8em')} mb="2em">
            <Text.H3 fontSize="1.875em" color="lightBlack">公告訊息</Text.H3>
          </Box>
          <NewsLayout path="/org/1/news" />
        </Container>
      </Box>
    </Box>
  )
}

export default IndexPage

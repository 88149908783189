import React from 'react'

import SVG from 'components/SVG'

const Pen = (props) => {
  return (
    <SVG viewBox="0 0 48 48" width="1em" {...props}>
      <path d="M6.4,25.6,25.6,6.4l16,16L22.4,41.6l-13.6-1Z" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="4"/>
      <path d="M6.6,25.4l16,16" fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth="4"/>
    </SVG>
  )
}

export default Pen
